.scrollItems {
  padding: 0 10px 0 0;
  height: calc(100vh - 220px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0.5em;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollItems::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}

.scrollItemsMobile {
  padding: 0 10px 0 0;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0.5em;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollItemsMobile::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}


.row-container::before,
.row-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #e4e4e4;
}

.scrollItems::-webkit-scrollbar {
  display: none;
}

.header {
  position: sticky;
  background: #ffffff;
  font-size: 28px;
  font-weight: 500;
}

.header01 {
  top: 0;
  z-index: 1000; 
}

.menu-box {
  border-left: 1px solid #e4e4e4;
  border-right:  1px solid #e4e4e4;
  width: max-content;
  min-width: 100px;
  padding: 5px 20px;
  background: white;
}

.menu-selected {
  background-color: #d32b2b;
  color: white;
}

.food-items-grid {
  display: flex;
  flex-wrap: wrap;
}

.food-item-box {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding: 0 !important;
  padding-bottom: 10px !important;
  overflow: hidden;
  height: 240px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.food-item-box-mobile {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding: 0 !important;
  padding-bottom: 10px !important;
  overflow: hidden;
  height: 280px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-container {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.green-button {
  background-color: #4CAF50; 
  border-color: #4CAF50; 
}

.card-image {
  width: 100%;
  height: 100px; 
  object-fit: cover; 
}

.col2 {
  border: 1px solid #ddd;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.price-label {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 10;
}

.image-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  color: black;
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 10;
}


.menu-section {
  margin-bottom: 2rem;
}

.text-weight {
  font-weight: bold;
}

.cursor{
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-muted {
  color: #6c757d;
}

.food-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


.loader-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); 
}

.content {
  display: none; 
}

.loader-overlay + .content {
  display: block; 
}