.box{
    /*border: 1px solid #adadad;*/
    border-radius: 5px;
    padding: 10px 20px;
    margin: 25px;
    background: white;
  }
  /* .scrollItems1{
    padding: 0 10px 0 0;
    height: calc(100vh - 380px);
    overflow-y: auto;
    overflow-x: hidden;
    margin:0.5em
  } */
  .scrollItems1 {
    padding: 0 10px 0 0;
    height: calc(100vh - 340px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0.5em;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .scrollItems::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
  }
  
  
  .loader-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); 
  }
  
  .content {
    display: none; 
  }
  
  .loader-overlay + .content {
    display: block; 
  }

