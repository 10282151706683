.extra-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.price-label {
  position: absolute;
  bottom: 10px;
  left: 10px;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  background-color: white;
  color: black;
  padding: 3px 7px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 10;
}
.price-label.selected-label {
  position: absolute;
  bottom: 10px;
  left: 10px;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  background-color: white;
  color: black;
  padding: 3px 7px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 10;
}

.selected {
  border-color: blue !important;
  border: 2px solid;
}

.selected-label {
  background-color: blue !important;
  color: white !important;
}

.image-container1 {
  position: relative;
  width: 100%;
  overflow: hidden;
}
