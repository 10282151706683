.img-fluid {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.position-relative {
  position: relative;
}

.left-arr,
.right-arr {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.left-arr {
  left: 10px;
}

.right-arr {
  right: 10px;
}

.arrow-button {
  background: none;
  border: none;
}
